import React, { useState } from "react"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import TextField from "@mui/material/TextField"
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete"
import Box from "@mui/material/Box"
import "./style.scss"

const filter = createFilterOptions({ matchFrom: "any" })

const USelect = ({
  placeholderLabel,
  options,
  onChange,
  className,
  onFocus,
  value,
  id,
  freeSolo = false,
  inputError,
  locationData = false,
  inputProps = {},
  overRideStyle = false,
  ...restProps
}) => {
  // const classes = useStyles()
  const [uniqueKey, setUniqueKey] = useState(
    freeSolo ? new Date().valueOf() : undefined
  )

  const isOptionEqualToValue = (option, value) => {
    if (locationData) {
      return typeof value === "string"
        ? option.description.includes(value)
        : option.description === value?.description
    }

    return typeof value === "string"
      ? option?.key === value || option?.label === value
      : option?.key === value?.key || option?.label === value?.label
  }

  const getOptionLabel = option => {
    if (locationData) {
      return typeof option === "string" ? option : option.description
    }

    return typeof option === "string" ? option : option.label
  }

  const filterOptions = (options, params) => {
    if (locationData) {
      return options
    }

    const filtered = filter(options, params)

    const { inputValue } = params
    // Suggest the creation of a new value
    const isExisting = options.some(option => inputValue === option.label)
    if (freeSolo && inputValue !== "" && !isExisting) {
      /* filtered.push({
        inputValue,
        key: inputValue,
        label: inputValue
      })*/
    }

    return filtered
  }

  const renderLocationOption = (props, option) => {
    return (
      <li {...props}>
        <Grid container alignItems="center">
          <Grid item sx={{ display: "flex", width: 44 }}>
            <LocationOnIcon sx={{ color: "text.secondary" }} />
          </Grid>
          <Grid
            item
            sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
          >
            <Box component="span">{option.structured_formatting.main_text}</Box>

            <Typography variant="body2" color="text.secondary">
              {option.structured_formatting.secondary_text}
            </Typography>
          </Grid>
        </Grid>
      </li>
    )
  }

  const renderOption = (props, option) => {
    if (locationData) {
      return renderLocationOption(props, option)
    }

    if (freeSolo) {
      if (typeof option === "string") {
        return <li {...props}>{option}</li>
      }

      // Add "xxx" option created dynamically
      /*if (option?.inputValue) {
        return (
          <li {...props}>
            <strong>Add</strong>&nbsp;
            {option.inputValue}
          </li>
        )
      }*/
    }

    return <li {...props}>{option?.label}</li>
  }

  const renderInput = params => {
    return (
      <TextField
        {...params}
        {...inputProps}
        label={placeholderLabel}
        error={Boolean(inputError)}
      />
    )
  }

  const handleOnChange = (e, value) => {
    setUniqueKey(new Date().valueOf())
    onChange(e, value)
  }

  return (
    <div className={`${overRideStyle ? '' : 'input-field-wrapper'} select-field-wrapper ${className?? ''}`}>
      <Autocomplete
        key={uniqueKey}
        id={id}
        freeSolo={freeSolo}
        options={options}
        renderInput={renderInput}
        onChange={handleOnChange}
        renderOption={renderOption}
        onFocus={onFocus}
        value={value || undefined}
        isOptionEqualToValue={isOptionEqualToValue}
        filterOptions={filterOptions}
        getOptionLabel={getOptionLabel}
        clearOnBlur
        {...restProps}
      />
    </div>
  )
}

export default USelect
